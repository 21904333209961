<template>
	<div class="px-4 py-2 box">
		<div class="py-2 text-red-700">
			拍摄照片前，请先校准相机时间（相机时间应该与电脑时间一致），如果存在时间差，将会导致照片匹配环节出现严重的错误
		</div>
		<div class="p-2 mt-4 text-sm font-bold">
			<a-radio-group v-model="modelType" class="ml-4">

				订单信息
				<a-radio-button value="new">
					新
				</a-radio-button>
				<a-radio-button value="old">
					旧
				</a-radio-button>

			</a-radio-group>

		</div>
		<div class="flex items-center py-2">
			<div class="w-1/2">订单号：{{ getOrderInfo.sn }}</div>
			<div class="w-1/2">尺码：{{ getOrderInfo.size }}</div>
		</div>
		<div class="flex justify-between p-2 mt-4 text-sm font-bold">
			<div>数据上传</div>
			<a class="text-red-500" style="text-decoration:underline" :href="downloadLink" download>模板下载</a>
		</div>
		<div class="py-2">
			<a-upload-dragger name="excel_file" :multiple="false" :action="action" :limit="1" @change="handleChange"
				:before-upload="beforeUpload" :data="data" accept=".xls, .xlsx" :headers="headers" ref="upload">
				<p class="ant-upload-drag-icon">
					<a-icon type="inbox" />
				</p>
				<p class="ant-upload-text">点击或者将文件拖入此处上传</p>
				<p class="ant-upload-hint">(仅允许上传excel文件)</p>
			</a-upload-dragger>
		</div>
		<div class="p-2 mt-4 text-sm font-bold">
			设备操作
		</div>
		<a-modal v-model:visible="visible" title="提示信息" @ok="handleOk">
			<p>{{getMessage}}</p>

		</a-modal>
		<div class="flex items-center justify-between mt-2">
			<div>
				<a-button type="primary" @click="connect" class="mb-2 mr-2">{{
          getIsConnect ? "关闭串口" : "打开串口"
        }}</a-button>
				<!-- <a-button type="primary" @click="submitData" class="mb-2 mr-2" v-if="getIsConnect">发送</a-button> -->
				<a-button type="primary" @click="autoCreateSubOrderFunc" class="mb-2 mr-2">自动生成子订单</a-button>
				<a-button type="primary" @click="robotReset" class="mb-2 mr-2" v-if="getIsConnect">机器人复位</a-button>
				<a-button type="primary" @click="robotStar" class="mb-2 mr-2" v-if="getIsConnect">机器人开始工作</a-button>
				<a-button type="primary" @click="robotEnd" class="mb-2 mr-2" v-if="getIsConnect">机器人暂停工作</a-button>
			</div>
		</div>
		<div class="p-2 py-3 font-bold rounded bg-gray-50">
			连接状态{{ getIsConnect ? "(已连接)" : "" }}：
			<span :class="getIsConnect ? 'text-primary' : 'text-gray-400'">{{
        getIsConnect ? getEquipmentInfo : "暂未连接串口设备"
      }}</span>
		</div>
		<div class="p-2 mt-4 text-sm font-bold">
			数据发送进度
		</div>
		<a-progress :stroke-color="{
        from: '#108ee9',
        to: '#87d068',
      }" :percent="(getStep / totalStep).toFixed(1) * 100" status="active" />
		<!-- <div class="pt-4">······
      <a-button type="primary" @click="sendRobotData" class="mr-2">数据入库</a-button>
    </div>-->
		<!-- <div class="pt-4">
      <a-button type="primary" @click="takingPicturesRequest" class="mr-2">拍照</a-button>
    </div>-->
		<div class="pt-4">
			<div class="py-2 text-base font-bold history">当前形变数据</div>
			<a-table :data-source="getCurrentData" :columns="columns" :scroll="{ x: 1300 }">
			</a-table>
		</div>
		<div class="pt-4">
			<div class="py-2 text-base font-bold history">数据表</div>
			<a-table :data-source="getDataSource" :columns="columns" :scroll="{ x: 1300 }">
				<template slot="operation" slot-scope="text, record">
					<div class="flex">
						<a-button type="default" class="mr-2" @click="modifyData(record.key)">修改</a-button>
						<a-popconfirm v-if="getDataSource.length" title="确定删除吗？" cancelText="取消" okText="确定"
							@confirm="() => deleteData(record.key)">
							<a-button type="danger">删除</a-button>
						</a-popconfirm>
					</div>
				</template>
			</a-table>
		</div>
	</div>
</template>

<script>
	import {
		mapState,
		mapGetters
	} from "vuex";
	import {
		createSubOrder,
		autoCreateSubOrder,
		getOrderStatus,
		updateStatus
	} from "@/api/collect";
	import {
		getMotorRecommendValue,
		getModel,
		getJSONObjectList
	} from "@/api/newrobot";
	import {
		BASE_URL
	} from "@/utils/common";

	import femaleRulerJson from '@/config/femaleRuler.json';
	import maleRulerJson from '@/config/maleRuler.json';
	export default {
		data() {
			return {
				money: "",
				visible: false,
				num: "",
				modelInfo: "",
				motorConfigList: [],
				modelArray: [],
				transDateArr: [],
				modifyIndex: -1, //  用户修改的数据索引值
				getMessage: '',
				columns: [],
				linshiinput: {
					"x4": {
						"key": "x4",
						"excelColumnIndex": 11,
						"ratio": 1,
						"title": "右肩头",
						"index": 1,
						"sum": 0
					},
					"x6": {
						"key": "x6",
						"excelColumnIndex": 12,
						"ratio": 1,
						"title": "右胸高",
						"index": 2,
						"sum": 10
					},
					"x1": {
						"key": "x1",
						"excelColumnIndex": 13,
						"ratio": 1,
						"title": "颈部",
						"index": 3,
						"sum": 2
					},
					"no1": {
						"key": "",
						"excelColumnIndex": "",
						"ratio": 0,
						"title": "",
						"index": 4,
						"sum": 0
					},
					"x7": {
						"key": "x7",
						"excelColumnIndex": 14,
						"ratio": 1,
						"title": "前胸片",
						"index": 5,
						"sum": 0
					},
					"x3": {
						"key": "x3",
						"excelColumnIndex": 15,
						"ratio": 1,
						"title": "左肩头",
						"index": 6,
						"sum": 0
					},
					"x5": {
						"key": "x5",
						"excelColumnIndex": 16,
						"ratio": 1,
						"title": "左胸高",
						"index": 7,
						"sum": 0
					},
					"x8": {
						"key": "x8",
						"excelColumnIndex": 17,
						"ratio": 1,
						"title": "后胸片",
						"index": 8,
						"sum": 0
					},
					"x2": {
						"key": "x2",
						"excelColumnIndex": 18,
						"ratio": 1,
						"title": "挑肩部",
						"index": 9,
						"sum": 0
					},
					"no2": {
						"key": "",
						"excelColumnIndex": "",
						"ratio": 0,
						"title": "",
						"index": 10,
						"sum": 0
					},
					"y6": {
						"key": "y6",
						"excelColumnIndex": 19,
						"ratio": 1,
						"title": "前腰下片",
						"index": 11,
						"sum": 0
					},
					"y4": {
						"key": "y4",
						"excelColumnIndex": 20,
						"ratio": 1,
						"title": "右腰片",
						"index": 12,
						"sum": 0
					},
					"y7": {
						"key": "y7",
						"excelColumnIndex": 21,
						"ratio": 1,
						"title": "后腰片",
						"index": 13,
						"sum": 0
					},
					"no3": {
						"key": "",
						"excelColumnIndex": "",
						"ratio": 0,
						"title": "",
						"index": 14,
						"sum": 0
					},
					"y5": {
						"key": "y5",
						"excelColumnIndex": 22,
						"ratio": 1,
						"title": "前腰上片",
						"index": 15,
						"sum": 0
					},
					"no4": {
						"key": "",
						"excelColumnIndex": "",
						"ratio": 0,
						"title": "",
						"index": 16,
						"sum": 0
					},
					"y2": {
						"key": "y2",
						"excelColumnIndex": 23,
						"ratio": 1,
						"title": "腰下高",
						"index": 17,
						"sum": 0
					},
					"y3": {
						"key": "y3",
						"excelColumnIndex": 24,
						"ratio": 1,
						"title": "左腰片",
						"index": 18,
						"sum": 0
					},
					"y1": {
						"key": "y1",
						"excelColumnIndex": 25,
						"ratio": 1,
						"title": "腰上高",
						"index": 19,
						"sum": 0
					},
					"no5": {
						"key": "",
						"excelColumnIndex": "",
						"ratio": 0,
						"title": "",
						"index": 20,
						"sum": 0
					},
					"t2": {
						"key": "t2",
						"excelColumnIndex": 26,
						"ratio": 1,
						"title": "右臀片",
						"index": 21,
						"sum": 0
					},
					"t5": {
						"key": "t5",
						"excelColumnIndex": 27,
						"ratio": 1,
						"title": "后臀上片",
						"index": 22,
						"sum": 0
					},
					"t4": {
						"key": "t4",
						"excelColumnIndex": 28,
						"ratio": 1,
						"title": "前臀下片",
						"index": 23,
						"sum": 0
					},
					"no6": {
						"key": "",
						"excelColumnIndex": "",
						"ratio": 0,
						"title": "",
						"index": 24,
						"sum": 0
					},
					"no7": {
						"key": "",
						"excelColumnIndex": "",
						"ratio": 0,
						"title": "",
						"index": 25,
						"sum": 0
					},
					"no8": {
						"key": "",
						"excelColumnIndex": 32,
						"ratio": 0,
						"title": "右手",
						"index": 26,
						"sum": 0
					},
					"t3": {
						"key": "t3",
						"excelColumnIndex": 29,
						"ratio": 1,
						"title": "前臀上片",
						"index": 27,
						"sum": 0
					},
					"t1": {
						"key": "t1",
						"excelColumnIndex": 30,
						"ratio": 1,
						"title": "左臀片",
						"index": 28,
						"sum": 0
					},
					"t6": {
						"key": "t6",
						"excelColumnIndex": 31,
						"ratio": 1,
						"title": "后臀下片",
						"index": 29,
						"sum": 0
					},
					"no9": {
						"key": "",
						"excelColumnIndex": "",
						"ratio": 0,
						"title": "",
						"index": 30,
						"sum": 0
					}
				},
				errorMsg: "浏览器不支持串口通讯请更换浏览器或升级浏览器版本(谷歌，Edge，欧朋)",

				msgInputObject: {
					length: {
						name: "身高",
						num: 1,
					},

					shoulder: {
						name: "肩宽",
						num: 1,
					},
					arm: {
						name: "上臂围",
						num: 1,
					},
					bust: {
						name: "胸围",
						num: 1,
					},
					waist: {
						name: "腰围",
						num: 1,
					},
					hipline: {
						name: "臀围",
						num: 1,
					},
					hipheight: {
						name: "臀高",
						num: 1,
					},
					lower: {
						name: "下身长",
						num: 1,
					},
					thigh: {
						name: "大腿围",
						num: 1,
					},
					thigh_length: {
						name: "大腿长",
						num: 1,
					},
					calf: {
						name: "小腿围",
						num: 1,
					},
				},

				// 上传的文件的相关属性
				action: "",
				data: {},
				headers: {},

				downloadLink: "", // 模板下载路径
				modelType: 'new',
				modelSexType: 'women',
				ageType: 0,
				model: 'live',
				sn: '',
				totalStep: 1,
			};
		},
		computed: {
			...mapGetters([
				"getDataSource",
				"getInputObject",
				"getIsConnect",
				"getEquipmentInfo",
				"getStep",
				"getOrderInfo",
				"getCurrentData",
				"getSN",
				"getModelId"
			]),
			...mapState(["currentData"]),
		},
		watch: {
			// 监听订单的改变
			getOrderInfo: function() {
				this.data.order_id = this.getOrderInfo.id;
			},
		},
		created() {
			this.$store.commit("updateinputObject", localStorage.getItem("inputmodelid"));


			this.$store.commit("updateCurrentData", {
				type: "reset"
			});
			this.$store.commit("dataReset");
			this.action = BASE_URL + "/admin/Order/import";
			this.downloadLink =
				BASE_URL +
				"/static/%E6%9C%BA%E5%99%A8%E4%BA%BA%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx";
			const ACCESS_TOKEN = JSON.parse(localStorage.getItem("ACCESS_TOKEN"));
			this.headers["AdminToken"] = ACCESS_TOKEN.token;
			this.headers["AdminUserId"] = ACCESS_TOKEN.userId;
			let orderInfo = localStorage.getItem("orderInfo") ?
				JSON.parse(localStorage.getItem("orderInfo")) :
				"";
			if (orderInfo) {
				this.$store.commit("setOrderInfo", orderInfo);
			} else {
				this.$message.error("请先选择订单");
				setTimeout(() => {
					this.$router.push({
						name: "index",
					});
				}, 1000);
			}

			//this.initColumns();
			//  this.robotStar()
			// this.takingPicturesRequest()
		},
		methods: {
			// 文件上传之前
			async linshifun() {
				this.$store.commit("updateinputObject", localStorage.getItem("inputmodelid"));
				this.initColumns();
			},
			beforeUpload() {
				if (!this.getIsConnect) {
					console.log("执行了beforeUpload请先连接设备");
					this.$message.error(`请先连接设备`);
					return false;
				}
			},
			// 文件上传
			handleChange(info) {
				console.log(this.$refs.upload);
				const status = info.file.status;

				if (status === "done") {
					let res = info.file.response;
					console.log("asdfasdfdsfsdf", res);
					if (res.code == 200) {
						this.$message.success(`${info.file.name} 上传成功`);
						let data = info.file.response.data;
						this.$store.commit("addDataSource", data);
					} else {
						this.$message.error(res.msg);
					}
				} else if (status === "error") {
					this.$message.error(`${info.file.name} 上传失败`);
				}
			},
			// 测试拍照接口
			takingPicturesRequest() {
				this.$store.dispatch("takingPicturesFunc", {
					turns: 2,
					turnNumber: 20
				});
			},
			async initColumns() {
				this.columns = [];
				this.columns.push({
					title: "步骤值",
					dataIndex: "step",
					width: 75,
				});

				// 添加基础数据列
				for (let key in this.msgInputObject) {

					console.log("initColumns.msgInputObject", this.msgInputObject);

					this.columns.push({
						title: this.msgInputObject[key].name,
						dataIndex: key + "",
						width: 80,
					});
				}
				// 添加形变数据列

				for (let key in this.getInputObject) {

					if (this.getInputObject[key].title) {
						this.columns.push({
							title: this.getInputObject[key].title,
							dataIndex: key + "",
							width: 90,
						});
					}
				}
			},

			// 连接串口
			connect() {
				this.$store.commit("initStep");
				this.$store.commit("updateinputObject", localStorage.getItem("inputmodelid"));

				this.$store.dispatch("connect", this.modelType);

			},

			// 发送数据
			submitData() {


				if (this.getDataSource.length == 0) {
					this.$message.error("请先上传数据");
					return;
				}
				let size_ids = [];
				this.getDataSource.forEach((item) => {

					size_ids.push(item.size_id * 1);
				});
				createSubOrder({
						size_ids,
						order_id: this.getOrderInfo.id,
					})
					.then((res) => {
						console.log("创建子订单", res);
						if (res.code == 200) {

							if (this.modelType == 'old') {

								console.log(res.data);
								this.$store.commit("updateStep", 0); // 重置步骤值
								this.$store.commit("setSubOrder", res.data);
								this.$store.dispatch("submitData");
							} else {
								//根据子订单获取体型值
								let modelArray = [];
								for (let i = 0; i < res.data.length; i++) {
									let d = res.data[i];
									let modelData = {}
									modelData.neck_circumference = 320; //d.neck ? d.neck : 320;
									modelData.shoulder_width = +d.shoulder * 10;
									modelData.upper_arm_circumference = d.arm ? +d.arm * 10 : 220;
									modelData.chest_circumference = +d.bust * 10;
									modelData.waist_circumference = +d.waist * 10;
									modelData.hip_circumference = +d.hipline * 10;
									modelData.upper_body_length = +d.length * 10;
									modelArray.push(modelData);
								}

								console.log("调用setmodelArray");
								this.$store.commit("setmodelArray", modelArray);
								this.modelArray = modelArray;

								this.$store.commit("getModelInfo");
							}

						} else {
							console.log(res);
							this.$message.error(res.msg);
						}
					})
					.catch((error) => {
						console.log(error);
						this.$message.error(error);
					});
			},
			// 自动生成子订单
			autoCreateSubOrderFunc() {


				autoCreateSubOrder({
						order_id: this.getOrderInfo.id
					})
					.then((res) => {
						if (res.code == 200) {

							if (this.modelType == 'old') {

								this.$store.commit("addDataSource", {
									data: res.data,
									modelType: this.modelType
								});
								this.$store.commit("updateStep", 0); // 重置步骤值
								this.$store.commit("setSubOrder", res.data);
								this.$store.dispatch("submitData");
							} else {

								this.initColumns();

								//查询新版机器人变形数据
								let params = {};
								//女上模
								// if (this.modelSexType === 'men') {


								// } else {

								// }
								console.log("params.id===============")

								if (!this.getModelId) {

									this.$store.commit("getModelId");
								}

								params.id = this.getModelId;
								params.isTailor = "0";
								params.isRobot = "0";
								params.isCommon = "1";
								params.tag1 = '展览';
								params.limits = 5;

								console.log("getModelId===============", this.getModelId)

								getJSONObjectList(params).then((res) => {

									if (res.code == 200) {

										let resdata = res.result.data;
										this.totalStep = resdata.length;


										this.$store.commit("addDataSource", {
											data: resdata,
											modelType: this.modelType
										});
										this.$store.commit("initStep");
									}


								})


							}

						} else {
							this.$message.error(res.msg);
						}
					})
					.catch((error) => {
						this.$message.error(error);
					});
			},

			// 机器人复位
			robotReset() {
				this.$store.dispatch("robotReset", this.modelType);
			},
			// 机器人开始工作
			async robotStar(id) {
				let that = this;
				this.$EventBus.$on("completeModal", () => {
					that.$confirm({
						title: "提示",
						content: "采集完成",
						okText: "确定",
						cancelText: "取消",
						onOk: () => {
							console.log("OK");
							that.$EventBus.$off("completeModal")
							that.$router.push("/collectWork");
							this.robotReset();
						},
						onCancel() {
							that.$EventBus.$off("completeModal")
							that.$router.push("/collectWork");
							console.log("Cancel");
						},
						class: "test",
					});
				});

				//变形步骤
				this.initColumns();
				//旧机器人设置
				if (this.modelType === 'old') {
					await this.$store.commit("updateinputObject", localStorage.getItem("inputmodelid"));
				} else {
					//await this.$store.commit("newrobotStar", this.modelType);

				}




				let wwwid = localStorage.getItem(
					"orderInfo").order_id

				let ttt = JSON.parse(localStorage.getItem(
					"orderInfo"))
				// alert(ttt.order_id)
				await getOrderStatus({
					order_id: this.getOrderInfo.id
				}).then((res) => {

					console.log("getOrderStatus=====", res)
					if (res.code == 200) {
						switch (res.data.order_status) {
							case -1:
								this.visible = true
								this.getMessage = '该订单已经删除，是否继续执行'
								break;
							case 0:
								this.handleOk()
								break;
							case 1:
								this.visible = true
								this.getMessage = '该订单已取消，是否继续执行'
								break;
							case 2:
								this.visible = true
								this.getMessage = '该订单已采集，是否继续执行'
								break;
							case 3:
								this.visible = true
								this.getMessage = '该订单采集中，是否继续执行'
								break;
							case 4:
								this.visible = true
								this.getMessage = '该订单已上传，是否继续执行'
								break;

						}
					}
				})

				//原来的开始工作
				// this.$store.dispatch("robotStar");
				//原来的开始工作
			},
			handleOk() {
				this.visible = false
				let ttt = JSON.parse(localStorage.getItem("orderInfo"))

				console.log('start')

				//更新订单状态，暂时关闭
				updateStatus({
					order_id: this.getOrderInfo.id,
					order_status: 3
				}).then((res) => {

					console.log("updateStatus ===>", res)

				})

				if (this.modelType === 'old') {
					this.$store.dispatch("robotStar", this.modelType);
				} else {

					this.$store.commit("newrobotStar", this.modelType);
				}




			},
			// 机器人暂停工作
			robotEnd() {
				this.$store.dispatch("robotPause", this.modelType);
			},
			getmotorData(part, index) {

				//获取变形数据
				let param = {};
				//param.modelId = this.modeln.model;
				param.modelId = this.modelInfo.id;
				//param.modelId = "1702987914675568642";
				param.part = part;
				//年龄模式（默认：0；青年：-1；中老年：-2；自定义：25~85实际年龄值）
				param.ageType = 0;
				//最大误差值（mm）5~20
				param.maxSizeError = 20;
				//操作模式（直播模式live/打版模式tailor）			
				param.model = "live";
				//是否返回计算日志			
				param.logEnable = false;
				if (this.modelInfo.modelType == "male_upper_body_model") {

					param.ruler = maleRulerJson;
				} else {
					param.ruler = femaleRulerJson;
				}


				console.log("getmotorData===", param)
				getMotorRecommendValue(param).then((res) => {


					if (res.success) {

						if (res.result) {

							//电机对应行程
							let position = res.result.motorTravel;

							if (position) {
								let transDate = {};
								//transDate.model_data = this.formValidate;
								transDate.motor_data = position;
								this.transDateArr.push(transDate)
							}


							console.log("getMotorRecommendValue", this.transDateArr)
							//this.$store.commit("addDataSource", position);
							//this.$store.commit("updateStep", 0); // 重置步骤值
							//单次变形
							//this.$store.dispatch("setMotorData", position);
							//确认变形
							//this.$store.dispatch("configTransform", 1);

							//let currentMotorStatus =  this.$store.dispatch("readDeviceStatus");
							//console.log('currentMotorStatus', currentMotorStatus)
						} else {

							this.$message.error(`获取变形策略失败`);
						}

					} else {

						this.$message.error(`获取变形策略失败`);
					}

				});



			},
			async startWork2() {

				//获取变形数据
				let param = {};
				param.modelId = this.modeln.model;
				//param.modelId = "1702987914675568642";
				let part = {};
				//颈围
				part.neck_circumference = this.SizeC.neck ? +this.SizeC.neck : 320;
				part.shoulder_width = +this.SizeC.shoulder * 10;
				part.upper_arm_circumference = this.SizeC.arm ? +this.SizeC.arm * 10 : 220;
				part.chest_circumference = +this.SizeC.bust * 10;
				part.waist_circumference = +this.SizeC.waist * 10;
				part.hip_circumference = +this.SizeC.hipline * 10;
				part.upper_body_length = +this.SizeC.length * 10;

				console.log(this.SizeC)


				param.part = part;
				//年龄模式（默认：0；青年：-1；中老年：-2；自定义：25~85实际年龄值）
				param.ageType = 0;
				//最大误差值（mm）5~20
				param.maxSizeError = 20;
				//操作模式（直播模式live/打版模式tailor）			
				param.model = "live";
				//是否返回计算日志			
				param.logEnable = false;

				if (this.modelSexType === 'men') {

					param.ruler = maleRulerJson;
				} else {
					param.ruler = femaleRulerJson;
				}
				param.ageType = this.ageType;
				param.model = this.model;


				getMotorRecommendValue(param).then((res) => {


					if (res.success) {

						if (res.result) {

							//电机对应行程
							let position = res.result.motorTravel;

							//this.$store.commit("addDataSource", position);
							//this.$store.commit("updateStep", 0); // 重置步骤值
							//单次变形
							this.$store.dispatch("setMotorData", position);
							//确认变形
							//this.$store.dispatch("configTransform", 1);

							//let currentMotorStatus =  this.$store.dispatch("readDeviceStatus");
							//console.log('currentMotorStatus', currentMotorStatus)
						} else {

							this.$message.error(`获取变形策略失败`);
						}

					} else {

						this.$message.error(`获取变形策略失败`);
					}

				});

			},
		},
	};
</script>

<style lang="less" scoped>
	.item {
		width: 280px;
	}

	.item div:nth-child(1) {
		width: 70px;
	}

	.box {
		user-select: none;
	}
</style>